@import url('https://fonts.googleapis.com/css?family=Ubuntu');

input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  clip: rect(0, 0, 0, 0);
  visibility: hidden;
  opacity: 0;
  transform: matrix3d(0,0,0.00,0,0.00,0,0.00,0,0,0,1,0,0,0,0,1);
  z-index: -1;
}

input[id="custom-test1"]:checked ~ .custom-tabs .custom-tab label[for="custom-test1"]::after,
input[id="custom-test2"]:checked ~ .custom-tabs .custom-tab label[for="custom-test2"]::after,
input[id="custom-test3"]:checked ~ .custom-tabs .custom-tab label[for="custom-test3"]::after,
input[id="custom-test4"]:checked ~ .custom-tabs .custom-tab label[for="custom-test4"]::after,
input[id="custom-test5"]:checked ~ .custom-tabs .custom-tab label[for="custom-test5"]::after {
  opacity: 1;
}

input[id="custom-test1"]:checked ~ .custom-tabs .custom-tab label[for="custom-test1"],
input[id="custom-test2"]:checked ~ .custom-tabs .custom-tab label[for="custom-test2"],
input[id="custom-test3"]:checked ~ .custom-tabs .custom-tab label[for="custom-test3"],
input[id="custom-test4"]:checked ~ .custom-tabs .custom-tab label[for="custom-test4"],
input[id="custom-test5"]:checked ~ .custom-tabs .custom-tab label[for="custom-test5"] {
  color: white;
  cursor: default;
}
/* 
input[id="custom-test1"]:checked ~ .custom-panels #custom-panel1,
input[id="custom-test2"]:checked ~ .custom-panels #custom-panel2,
input[id="custom-test3"]:checked ~ .custom-panels #custom-panel3,
input[id="custom-test4"]:checked ~ .custom-panels #custom-panel4,
input[id="custom-test5"]:checked ~ .custom-panels #custom-panel5 {
  display: block;
} */

.custom-tabs-panel{
  position: sticky;
  top: 1px;
  background: #282c35;
  right: 0;
  z-index: 9;
}

.custom-tabs-panel .custom-tabs {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.custom-tabs-panel .custom-tab {
  flex: 1;
  position: relative;
  margin-top: 1.5rem;
  text-align: center;
  color: #707a89;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s ease;
}

.custom-tabs-panel .custom-tab label {
  display: block;
  height: 100%;
  padding: 0 0.75rem;
  border: none;
  cursor: pointer;
  position: relative;
}

.custom-tabs-panel .custom-tab .custom-content .custom-icon {
  margin-bottom: 1rem;
  font-size: 1.375rem;
  text-align: center;
}

.custom-tabs-panel .custom-tab .custom-content .custom-text {
  margin-bottom: 1.563rem;
  font-family: 'Ubuntu', 'Myriad Pro', sans-serif;
  font-size: 12px;
}

.custom-tabs-panel .custom-tab label::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  display: block;
  width: 100%;
  height: 3px;
  opacity: 0;
  background-color: #00CC9C;
  transition: opacity 0.3s ease-in-out;
}

.custom-tabs-panel .custom-tab:hover {
  color: #00CC9C;
}

.custom-tabs-panel .custom-separator {
  width: 100%;
  height: 3px;
  background-color: #363e4e;
}

.custom-panels {
  border: none;
}

/* .custom-tabs-panel .custom-tab-panel {
  display: none;
  border: none;
} */

.custom-tabs-panel .custom-tab-panel .custom-content {
  font-family: 'Ubuntu', 'Myriad Pro', sans-serif;
  color: #707a89;
  animation: fade 0.3s linear;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .custom-tabs-panel {
    top: 0
  }
}