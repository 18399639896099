@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: rebeccapurple;
  --secondary: cornflowerblue;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body,
html {
  height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 0.688rem;
  line-height: 17px;
  font-style: normal;
  font-weight: 400;
  overflow-x: hidden;
  color: #fff;
  background-color: #282C35;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #00cc9c white;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
  z-index: 999;
}

*::-webkit-scrollbar-track {
  background: #00cc9c;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #00cc9c;
  border-radius: 14px;
  border: 3px solid white;
}