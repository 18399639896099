h1 {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 1rem;
}


h2 {
    font-size: 1.25rem;
    font-weight: 500;
}

h3 {
    font-size: 1.125rem;
    font-weight: 500;
}

h4 {
    font-size: 1rem;
    font-weight: 500;
}

h5 {
    font-size: 0.938rem;
}

/* Font Sizes Start */

.body-medium {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.125rem;
}

.body-regular {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
}

.body-small-bold {
    font-size: 0.688rem;
    font-weight: 600;
    line-height: 1.031rem;
}

.body-small-regular {
    font-size: 0.688rem;
    font-weight: 400;
    line-height: 1.031rem;
}

/* Font Sizes End */


.color-primary {
    background-color: #00CC9C;
}

.color-secondary {
    background-color: #f2f2f2;
}

.text-secondary {
    color: #f2f2f2;
}

.full-height {
    height: 100%;
}

.btn-secondary {
    background-color: #f2f2f2;
    color: #333;
    border: 1px solid #ccc;
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s;
}

.loader-center {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999999 !important;
}

/* Master styles that can be used across the entire application */
.content-wrap {
    flex: 1;
    /* Allows content to expand */
    /* Remove the comment syntax and ensure this is active */
}

/* Center Content */
.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-column {
    flex-direction: column;
    /* Stack children vertically */
}

.content-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* This class can be used to center content horizontally within its parent */
.horizontal-center {
    display: flex;
    justify-content: center;
}

/* This class can be used to center content vertically within its parent */
.vertical-center {
    display: flex;
    align-items: center;
}

.center-vertical-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* This class can be used to center content both vertically and horizontally within its parent */
.full-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* This class can be used to center text within its container */
.text-center {
    text-align: center;
}

/* This class can be used for buttons or other clickable elements to indicate action */
.btn-center {
    display: block;
    /* Change to block to allow margin auto to work */
    margin-left: auto;
    margin-right: auto;
}

.margin-bottom-2rem {
    margin-bottom: 2rem;
}

/* Containers */

.page-container {
    background-color: #101010;
    padding: 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.08) 5px 5px 30px;
    border-radius: 15px;
    margin-top: 1.3rem;
}

/* Common header for pages */
.page-header {
    color: #fff;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
}


.cards {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    border-left: 5px solid #00CC9C;
    margin: 1.5rem 0;
    background-color: #1a1c1b;
    box-shadow: rgba(0, 0, 0, 0.05) 3px 3px 5px;
    border-radius: 15px;
    padding: 0.8rem;

    &:hover {
        border-left: 5px solid #00a47e;
        background: #292b2a;
    }
}

/* Container for each major section */
.section-container {
    background-color: #101010;
    border-radius: 15px;
    padding: 1.5rem;
    margin-block: 1rem;
    margin-bottom: 1rem;
    overflow: clip;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.section-container--max {
    min-height: 400px;
    max-height: 400px;
    height: 100%;
    overflow-y: auto;
    justify-content: flex-start;
}

body .accordion-button:not(.collapsed) {
    background-color: #00cc9ce8;
    box-shadow: 0 0 0 0.25rem rgb(57 209 165 / 60%);
    color: white;
}

body .accordion-button:focus {
    box-shadow: 0 0 0 0.25rem rgb(57 209 165 / 60%);
}

/* Common header for sections */
.section-header {
    color: #333;
    margin-top: 0.3rem;
    margin-bottom: 1rem;
    text-align: left;
}

/* Styled list for sections */
.section-list {
    list-style: none;
    padding: 0;
}

/* Styled list item */
.section-list-item {
    background-color: #fff;
    border: 1px solid #e1e1e1;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.section-list-item:hover {
    background-color: #eef6ff;
}

.section-list-item:hover {
    background-color: #eef6ff;
}

.horizontal-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* Cards spaced evenly horizontally */
}

/* Style for each individual card */
.two-columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* Cards spaced evenly horizontally */
    padding: 0rem;
    margin-block: 1rem;
    margin-bottom: 1rem;
    align-items: stretch;
}

.colored-box {
    display: flex;
    height: 5rem;
    width: 15rem;
    border-radius: 5px;
    /* Add rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.width-50 {
    width: 50rem;
}

.equal-height {
    display: flex;
    flex-wrap: wrap;
}

.equal-height>* {
    flex: 1;
    margin: 0 10px;
    /* Add margin between cards as needed */
}

/* Styles for input fields */
body input[type="text"],
body input[type="date"],
body input[type="email"],
body input[type="tel"],
body input[type="number"],
body input[type="file"],
body textarea,
body select,
body textarea {
    display: block;
    width: 100%;
    background: theme('colors.monochrome.60');
    border-bottom: 1px solid theme('colors.monochrome.40');
    border-top: none;
    border-left: none;
    border-right: none;
    color: white;
    padding: 7px 8px;
    font-size: 0.688rem;
    font-weight: 400;
    line-height: 1.5;
    border-radius: 0px;
    box-sizing: border-box;
    outline: none;
    min-height: 42px;
}

input[type="text"]:focus,
input[type="date"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
body textarea:focus {
    border: 1px solid theme('colors.green.500');
    --tw-ring-color: none;
}

/* Penomo Button */
.btn-penomo,
.btn {
    background-color: #00CC9C;
    /* Penomo color */
    color: #000;
    /* Text color set to black */
    border: none;
    /* Removes any default border */
    padding: 10px 15px;
    /* Padding for size */
    border-radius: 5px;
    /* Rounded corners */
    font-weight: 600;
    /* Bold font */
    cursor: pointer;
    /* Cursor indicates clickable button */
    outline: none;
    /* Removes outline on focus for some browsers */
    transition: background-color 0.3s;
    /* Smooth transition for hover effect */
    display: block;
}

.btn-penomo:hover,
.btn:hover {
    background-color: #00CC9C;
    /* This will make the button green when clicked */
    color: white;
    /* Optional: change text color to white when active/selected */
    /* Any other styles you want to apply when the button is clicked */
}

.btn-penomo.btn-disabled,
.btn-penomo:disabled {
    background-color: #00cc9c96;
    cursor: not-allowed;
    pointer-events: none;
}

.btn-penomo:active,
.btn:active {
    background-color: #00CC9C;
    /* This will make the button green when clicked */
    color: white;
    /* Optional: change text color to white when active/selected */
    /* Any other styles you want to apply when the button is clicked */
}

.btn-penomo-secondary {
    background-color: #9bbab6;
    /* Penomo color */
    color: #000;
    /* Text color set to black */
    border: none;
    /* Removes any default border */
    padding: 10px 15px;
    /* Padding for size */
    border-radius: 5px;
    /* Rounded corners */
    font-weight: bold;
    /* Bold font */
    cursor: pointer;
    /* Cursor indicates clickable button */
    outline: none;
    /* Removes outline on focus for some browsers */
    transition: background-color 0.3s;
    /* Smooth transition for hover effect */
    display: block;
    /* Add this line */
    margin: auto;
}

.btn-penomo-secondary:active {
    background-color: #00CC9C;
    /* This will make the button green when clicked */
    color: white;
    /* Optional: change text color to white when active/selected */
    /* Any other styles you want to apply when the button is clicked */
}

.btn-penomo-secondary:hover {
    background-color: #00CC9C;
    /* This will make the button green when clicked */
    color: white;
    /* Optional: change text color to white when active/selected */
    /* Any other styles you want to apply when the button is clicked */
}

#navbar-default a.active {
    font-weight: 600;
    /* Bold font */
    color: #00CC9C;
}

#navbar-default a {
    font-size: 0.688rem;
}

.select-dropdown div div {
    color: #9e9e9e !important;
    font-size: 0.688rem;
    font-weight: 400;
}


.nav-icon {
    height: 24px;
    width: 24px;
}

.nav-button {
    min-width: 200px;
}

.toggle-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.toggle-button {
    background-color: #f0f0f0;
    /* Light grey background for the non-active button */
    color: #333;
    /* Dark text for contrast */
    border: 1px solid #ccc;
    /* Light grey border for non-active buttons */
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s;
    margin-right: 5px;
    /* Space between buttons */
}

.toggle-button:last-child {
    margin-right: 0;
    /* Remove margin for the last button */
}

.toggle-button.active,
.toggle-button:hover {
    background-color: #00CC9C;
    /* Penomo color for active/hover state */
    color: #000;
    /* Text color set to black for active/hover state */
    border: 1px solid #00CC9C;
    /* Penomo color border for active/hover state */
}

.info-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #00CC9C;
    /* Penomo green */
    color: #ffffff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: bold;
}

/* Popup styles */
.popup-header {
    position: relative;
    /* This ensures that absolute positioning is relative to this container */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

/* Make sure the title does not overlap with the close button */
.popup-header h2 {
    padding-right: 40px;
    /* Adjust as needed */
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure it's on top */
    padding: 1rem;
    /* Add padding to ensure margin from the window borders */
    box-sizing: border-box;
    /* Include padding in the width and height calculations */
}

.popup-content {
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    width: calc(100% - 2rem);
    /* Adjust width to account for padding */
    max-width: 500px;
    display: flex;
    /* Use flexbox to align children */
    flex-direction: column;
    /* Stack children vertically */
    justify-content: center;
    /* Center children vertically */
    align-items: center;
    /* Center children horizontally */
    box-sizing: border-box;
    /* Include padding in the width and height calculations */
}

/* Close button */
.close {
    position: absolute;
    /* Absolute position for the close button */
    top: 1px;
    /* Adjust as needed to move up towards the top corner */
    right: 1px;
    /* Adjust as needed to move towards the right corner */
    cursor: pointer;
    font-size: 1.5rem;
    /* Adjust the size as needed */
    line-height: 1;
    color: #333;
    /* Adjust the color as needed */
}

.close:hover {
    color: #333;
}

.form-label {
    flex-basis: 30%;
    text-align: left;
}

.error {
    word-wrap: break-word;
    /* Ensures that the text breaks onto the next line and does not overflow */
    font-size: 0.8em;
    /* Adjust the font size as needed */
    color: red;
    /* Example color for error messages */
    margin-top: 0.5em;
    /* Adjust space above the error message if needed */
}


/* GENERAL ACCORDION STYLING */
.accordion {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
}

.accordion-button {
    font-weight: bold;
}

.accordion-body {}

.accordion-button:not(.collapsed) {}


/* GENERAL CARD STYLING */

.card {
    max-width: 700px;
    width: 100%;
    background-color: #1a1c1b;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile-card {
    max-width: 700px;
    width: 100%;
    background-color: #1a1c1b;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    input,
    select {
        background-color: #000;
        color: white !important;
        min-height: 42px;
    }
}

.mcard {
    background-color: #1a1c1b;
    color: #fff;
    border-radius: 8px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    @apply md:w-[32%]
}

/* GENERAL FORM STYLING */

.form {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    max-width: 100%;
    /* Ensure the form does not exceed the width of its container */
    margin: auto;
    /* Center the form horizontally */
}

.form-header {
    text-align: center;
    margin: 2rem;
}

.form-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* Space between form groups */
    padding: 0 20px;
    /* Equal padding on both sides */
    justify-content: left;
    /* Center the form groups horizontally */
    align-items: left;
    /* Align the form groups vertically */

}

.form-body-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    /* Space between form groups */
    padding: 0 20px;
    /* Equal padding on both sides */
    justify-content: left;
    /* Center the form groups horizontally */
    align-items: left;
    /* Align the form groups vertically */

}

/* .form-group {
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    flex: 0 1 100%; /* Full width on small screens 
} */

.form-group {
    display: flex;
    flex-direction: column;
    /* Stack elements vertically */
    align-items: flex-start;
    /* Align items to the start of the flex container */
    width: 100%;
    /* Take the full width */
}


.form-label {
    display: block;
    /* Make label a block-level element */
    /* width: 100%; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.form-control {
    width: 100%;
    /* Ensure input takes full width */
    flex-grow: 1;
    /* Allow the input to grow to fill the space */
    /* margin-right: 2rem; */
}



.form-separate-group {
    border: 1px solid black;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 5px;
}


/******* Profile Page Styling ******/

body .profilePage input {
    font-size: 11px;
    font-weight: 400;
    min-height: 31px;
    background: #25272d;
    border-bottom: 1px solid #383838;
}

body .profilePage input[type="text"]:focus,
body .profilePage input[type="date"]:focus,
body .profilePage input[type="email"]:focus,
body .profilePage input[type="tel"]:focus,
body .profilePage input:focus {
    border: none;
    border-bottom: 1px solid #00cc9c;
}

body .profilePage .css-q54ntu-control,
body .profilePage .css-13egw4w-control {
    min-height: 31px;
    border-bottom: 1px solid #383838;
    background: #25272d;
}

.profilePage .css-19bb58m,
.profilePage .css-1yt0726 {
    margin: 0px;
    padding: 0;
}

.profilePage .css-1xc3v61-indicatorContainer,
.profilePage .css-15lsz6c-indicatorContainer {
    padding: 4px 8px 4px 8px;
    height: 31px;
}

.profilePage .css-hlgwow {
    padding-top: 0;
    padding-bottom: 0;
}

.profilePage .css-b62m3t-container {
    background: #25272d;
}

.profilePage .css-q54ntu-control:hover,
.profilePage .css-q54ntu-control:focus {
    border-color: none;
}

.profilePage .css-q54ntu-control,
.profilePage .css-q54ntu-control:hover {
    box-shadow: 0 1px 0px #00cc9c;
}

.profilePage .css-1u9des2-indicatorSeparator {
    background-color: #9e9e9e;
}

/*******End Profile Page Styling******/


/* Medium screens (e.g., tablets) */
@media (min-width: 600px) {
    .form-group {
        flex: 0 1 calc(50% - 20px);
        /* Two columns, accounting for gap */

    }
}

/* Large screens (e.g., desktops) */
@media (min-width: 900px) {
    .form-group {
        flex: 0 1 calc(33.33% - 20px);
        /* Three columns, accounting for gap */
    }
}

/* Extra large screens */
@media (min-width: 1200px) {
    .form-group {
        flex: 0 1 calc(25% - 20px);
        /* Four columns, accounting for gap */
    }
}

.form-control {
    width: 100%;
    /* Make sure the input takes the full width of the form-group */
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
}


.form-footer {
    width: 100%;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}


/* Styles for the submit button */
.form-button[type="submit"] {
    font-weight: bolder;
    grid-column: span 2;
    padding: 10px;
    background-color: #00CC9C;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.form-button[type="submit"]:hover {
    color: white;
    /* Darker shade on hover */
}


.modal-body {
    padding: 20px;
    /* Adjust the padding as needed */
}

.modal-body p {
    word-wrap: break-word;
    /* This ensures that long words will wrap and not overflow */
    margin-bottom: 1rem;
    /* Adds some space below each paragraph if you have multiple */
}

.modal-footer {
    width: 100%;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;

}

.progress-bar {
    width: 100%;
    height: 35px;
    border: 1px solid #9bbab6;
    background-color: #9bbab6;
    /* border-radius: 5px; */
    /* margin-top: 10px; */
    /* display: flex; Remove this line */
    /* align-items: center; Remove this line */
    position: relative;
    /* Add this line */
    border-radius: 8px;
    padding: 1.2rem;
    margin-block: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.filler {
    background-color: #00CC9C;
    height: 100%;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    position: absolute;
    /* Use absolute positioning */
    left: 0;
    /* Align to the left */
    top: 0;
    /* Align to the top */
    bottom: 0;
    /* Align to the bottom */
}

.balance-display {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    /* Three columns with equal width */
    gap: 10px;
    /* Adjust the gap as needed */
    align-items: left;
    /* Center-align items vertically */
    align-items: center;
}

.percentage-text {
    color: #000000;
    font-weight: bold;
    position: absolute;
    /* Use absolute positioning */
    left: 50%;
    /* Center horizontally */
    top: 50%;
    /* Center vertically */
    transform: translate(-50%, -50%);
    /* Center text precisely */
}

.vh-center {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

/* Additional styles for responsiveness and aesthetics */
@media (max-width: 768px) {
    .popup-content {
        width: 90%;
        padding: 1rem;
    }

    .form-label {
        flex-basis: 40%;
    }

    .form-control {
        flex-basis: 60%;
    }

    .form-body {
        grid-template-columns: 1fr;
        /* Stack the columns on top of each other */
    }

}

/* Specific styles for flip card transactions */
.cards.flipCard-transaction {
    border-left: 5px solid #FFD700;
    /* Gold color for flip card */
}


.kyc-banner {
    cursor: pointer;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}




@media only screen and (max-width: 640px) {
    .mobile-space {
        padding-top: 16px
    }
}

.notification-card-wrapper {
    position: relative;
    width: 80%;
    z-index: 10;
    padding: 1rem 0;
}

.notification-card {
    background-color: rgb(26 28 34);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    margin: 0 auto;
}

/* Main calendar container */
.calendar-container {
    background-color: #1e1e1e;
    padding: 16px;
    border-radius: 8px;
    color: #fff;
    display: inline-block;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-top: 16px;
  }
  
  /* Dropdowns for month and year */
  .calendar-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .calendar-header select {
    background-color: #333;
    color: #fff;
    padding: 8px;
    border: 1px solid #444;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Styling for calendar */
  .react-calendar {
    background-color: #1A1C22;
    border: none;
    color: #fff;
    border-radius: 8px;
    padding:10px 0 0;
  }
  
  .react-calendar__navigation {
    background-color: #1A1C22;
    color: #fff;
    margin-bottom: 10px;
  }
  
  .react-calendar__tile {
    background-color: transparent;
    color: #fff;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    transition: background-color 0.3s ease;
  }
  .react-calendar__tile:focus-visible{
    outline: 0;
  }
  .react-calendar__tile--now {
    background-color: #00cc9c; /* Gray background for current date */
    color: #fff;
  }
  
  .react-calendar__tile--active {
    background-color: #00cc9c; /* Green background for selected date range */
    color: #fff;
  }
  
  .react-calendar__tile--range {
    background-color: #243c3f; /* Darker green for date range */
    color: #fff;
    border-radius: 0;
  }
  
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #00b89381;
    color: #fff;
  }
  
  .react-calendar__month-view__weekdays {
    background-color: #1A1C22;
    color: #bbb;
    padding-bottom: 10px;
  }
  
  .react-calendar__month-view__weekdays__weekday {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.875rem;
  }
  .react-calendar__month-view__weekdays__weekday abbr:where([title]){
    text-decoration: none;
    color: #fff;
  }
  .transactions-page .transactions-date input{
    border-radius: 8px;
    line-height: 20px;
    font-size: 14px;
    text-align: center;
  }
  body .transactions-dropdown input{
    min-height: auto;
  }
  body .transactions-dropdown div{
    font-size: 14px;
    line-height: 20px;
  }
  body .transactions-dropdown:focus{
    border: 2px solid #383838;
  }
  .react-calendar__navigation__arrow,
  .react-calendar__navigation__label{
    display: none;
  }
  body .month-year-dropdown select{
    border: 2px solid #383838;
  }
  body .month-year-dropdown select:focus{
    --tw-ring-color: #00cc9c;
  }
  body .transactions-dropdown{
    outline: 1px solid transparent !important;
  }
  body .transactions-dropdown:focus{
    outline-color: #383838 !important;
  }

  /* Date input fields */
  .date-inputs {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 10px;
  }
  
  .date-inputs input {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 8px;
    border-radius: 4px;
  }
  
  .date-inputs span {
    color: #fff;
  }
  
  /* Arrow button for date selection */
  .arrow-button {
    background-color: #00b894;
    border: none;
    padding: 8px;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .arrow-button:hover {
    background-color: #00a884;
  }
