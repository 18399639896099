.sticky-header-custom {
    position: sticky;
    top: 0;
    background: #282c35;
    right: 0;
    height: 67px;
}

/* Notification css */

.notification-button {
    width: fit-content;
    height: fit-content;
    background: transparent !important;
    padding: 0 !important;
}

.card-title {
    font-weight: 600;
}

.card-footer button {
    color: #007bff;
}

.card-footer button:hover {
    text-decoration: underline;
}

.card-body {
    position: relative;
    /* padding-right: 2.5rem; */
}