.marketplace-card {
    width: 100%;
    background-color: #1a1c1b;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 2rem;
}


.details-wraper .text-gray {
    width: 45%;
    min-width: 40%;
}

.details-wraper{
    font-size: 12px;
    /* border-bottom: 1px solid #3C3F48; */
}

.custom-card {
    background-color: #1A1C22;
    /* Background color similar to the image */
    color: #ffffff;
    /* Text color */
    border: none;
    /* Remove border */
    /* margin-bottom: 1rem; */
    /* Space between cards */
}

.custom-card h5 {
    font-size: 15px;
    /* Border for card titles */
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
}

.custom-card .card-body .details-wraper {
    padding: 1rem 0;
    /* Space between rows */
}

.custom-card .invest-card {
    text-align: center;
}

.custom-card .invest-card h4 {
    margin-bottom: 1rem;
    /* Space below the title */
}

.custom-card .invest-card hr {
    background-color: #ffffff;
    /* White color for horizontal line */
    opacity: 1;
    margin: 1rem 0;
}

.custom-card .invest-card .btn-success {
    background-color: #00A86B;
    /* Custom green color for button */
    border-color: #00A86B;
}

.custom-card .invest-card .btn-success:hover {
    background-color: #008556;
    /* Darker green on hover */
    border-color: #008556;
}