nav {
    @apply xl:fixed xl:top-0 xl:left-0 xl:w-full xl:z-20 xl:h-screen;
}

.navbar-logo {
    /* adjust the logo styles if needed */
    max-height: 40px;
}

.mobile-menu {
    display: flex !important;
    position: absolute;
    top: 80px;
    right: 0px;
    background: #000;
    z-index: 11;
    width: 100%;
    border-top: 1px solid theme('colors.green.500');
    left: 0;
    min-height: calc(100vh - 80px);
    transition: all 0.3s;
}

.nav-link {
    text-align: left;
    /* Align text to the left */
    padding: 10px 20px;
    /* Add some padding */
    border-bottom: 1px solid #ddd;
    /* Separator between items */
}

/* Existing button styles */

/* New styles for Settings link/button */
.btn-settings-navbar {
    background-color: #4B6587;
    /* Example color */
    /* Rest of the button styles similar to .btn-penomo-navbar */
}

/* Add any additional styles as needed */

.btn-penomo-navbar {
    background-color: #00CC9C;
    /* Penomo color */
    color: #000;
    /* Text color set to black */
    border: none;
    /* Removes any default border */
    padding: 10px 20px;
    /* Padding for size */
    border-radius: 5px;
    /* Rounded corners */
    font-weight: 600;
    /* Bold font */
    cursor: pointer;
    /* Cursor indicates clickable button */
    outline: none;
    /* Removes outline on focus for some browsers */
    transition: background-color 0.3s;
    /* Smooth transition for hover effect */
    /* margin-top: 0.5rem; */
    margin: auto;
    margin-left: 0.5rem;
    text-decoration: none;
    /* Removes underline from links if it's an <a> element */
    display: block;
    /* Makes the link behave like a block element */
    justify-content: center;
    align-items: center;
}

.btn-penomo-navbar:active {
    background-color: #00CC9C;
    /* This will make the button green when clicked */
    color: white;
    /* Optional: change text color to white when active/selected */
    /* Any other styles you want to apply when the button is clicked */
}

.btn-penomo-navbar:hover {
    background-color: #00CC9C;
    /* This will make the button green when clicked */
    color: white;
    /* Optional: change text color to white when active/selected */
    /* Any other styles you want to apply when the button is clicked */
}

.btn-secondary-navbar {
    background-color: #9bbab6;
    /* Penomo color */
    color: #000;
    /* Text color set to black */
    border: none;
    /* Removes any default border */
    padding: 10px 20px;
    /* Padding for size */
    border-radius: 5px;
    /* Rounded corners */
    font-weight: 400;
    /* Bold font */
    cursor: pointer;
    /* Cursor indicates clickable button */
    outline: none;
    /* Removes outline on focus for some browsers */
    transition: background-color 0.3s;
    margin: auto;
    margin-left: 0.5rem;
    text-decoration: none;
    /* Removes underline from links if it's an <a> element */
    display: block;
    /* Makes the link behave like a block element */
    justify-content: center;
    align-items: center;
}

.btn-secondary-navbar:active {
    background-color: #00CC9C;
    /* This will make the button green when clicked */
    color: white;
    /* Optional: change text color to white when active/selected */
    /* Any other styles you want to apply when the button is clicked */
}

.btn-secondary-navbar:hover {
    background-color: #00CC9C;
    /* This will make the button green when clicked */
    color: white;
    /* Optional: change text color to white when active/selected */
    /* Any other styles you want to apply when the button is clicked */
}

.btn-tertiary-navbar {
    display: flex;
    align-items: center;
    /* Penomo color */
    color: #9E9E9E;
    /* Text color set to black */
    border: none;
    /* Removes any default border */
    border-radius: 5px;
    /* Rounded corners */
    font-weight: 400;
    /* Regular font */
    cursor: pointer;
    /* Cursor indicates clickable button */
    outline: none;
    /* Removes outline on focus for some browsers */
    transition: background-color 0.3s;
    /* Smooth transition for hover effect */
    /* margin: auto .5rem; */
    text-decoration: none;
}

.btn-tertiary-navbar.active p{
    display: block;
 }
 
.btn-tertiary-navbar:hover {
    /* background-color: #00CC9C; */
    /* This will make the button green when clicked */
    color: #00CC9C;
    /* Optional: change text color to white when active/selected */
    /* Any other styles you want to apply when the button is clicked */
}
.btn-tertiary-navbar p{
    background-color: #00CC9C;
}