.investment-card {
  position: relative;
  width: 100%;
  color: white;
  /* padding: 24px !important; */
  overflow: hidden; /* Ensure child elements don't overflow */
  background-color: rgba(0, 0, 0, 0.8); /* Fallback background color */
}

/* .investment-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../../../../assets/marketplaceDetalis.png") no-repeat center center;
  background-size: cover;
  opacity: 0.3; 
  z-index: 1;
} */

.investment-card .header,
.investment-card .content {
  position: relative;
  z-index: 2;
}

.tab-container {
  width: 35%;
}

.header {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.tab {
  font-size: 11px;
  font-weight: 400;
  flex-grow: 1; /* Allow the tabs to grow */
  flex-shrink: 0; /* Prevent shrinking */
  text-align: center;
  padding: 10px;
  background-color: #1a1c2280;
  border-radius: 5px;
  margin: 0 5px;
  white-space: nowrap; /* Prevent text wrapping */
}

.content {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 2rem;
  line-height: 1;
  font-weight: semibold;
  margin-bottom: 8px;
}

.text-gray {
  color: #9e9e9e;
}

.subtitle {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.country {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.invest-button {
  background-color: #00c853;
  border: none;
  padding: 10px 20px;
  margin: 10px 0;
}

.info {
  display: flex;
  align-items: flex-start;
  gap: 30px;
}

.info-item {
  gap: 5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-direction: column;
}

.info-label {
  font-size: 12px;
  margin-right: 10px;
}

.info-value {
  font-size: 15px;
  font-weight: bold;
}

@media (max-width: 500px) {
  .info {
    gap: 5px;
  }

  .title {
    line-height: 1.3;
    margin-bottom: 0;
  }
}
